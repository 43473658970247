import { useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../initFirebase/initFirebase";


const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [userLoading, setUserLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setUserLoading(false);
    });
    return unsubscribe;
  }, []); 

  return (
    <AuthContext.Provider 
      value={{ currentUser, userLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
