export const daylist = [
    {
        id: "01",
        title: "DAY 1 🌙",
        icon: "",
        time: "02:06:14",
        bgcol: "#C6F6D5",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 2,
                name: "Al-Baqarah",
                ayah: "286",
                time: "02:05:11"
            }
        ],
    },
    {
        id: "02",
        title: "DAY 2 🌒",
        icon: "",
        time: "01:19:52",
        bgcol: "#FEFCBF",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 3,
                name: "Al-e-Imran",
                ayah: "200",
                time: "01:18:48"
            }
        ],
    },
    {
        id: "03",
        title: "DAY 3 🌒",
        icon: "",
        time: "01:20:55",
        bgcol: "#B2F5EA",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 4,
                name: "An-Nisa",
                ayah: "177",
                time: "01:19:51"
            }
        ],
    },
    {
        id: "04",
        title: "DAY 4 🌒",
        icon: "",
        time: "01:02:55",
        bgcol: "#FEEBC8",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 5,
                name: "Al-Ma'idah",
                ayah: "120",
                time: "01:02:51"
            }
        ],
    },
    {
        id: "05",
        title: "DAY 5 🌓",
        icon: "",
        time: "01:13:25",
        bgcol: "#BEE3F8",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 6,
                name: "Al-An'am",
                ayah: "166",
                time: "01:12:20"
            }
        ],
    },
    {
        id: "06",
        title: "DAY 6 🌓",
        icon: "",
        time: "01:24:00",
        bgcol: "#FED7D7",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 7,
                name: "Al-A'raf",
                ayah: "206",
                time: "01:22:56"
            }
        ],
    },
    {
        id: "07",
        title: "DAY 7 🌓",
        icon: "",
        time: "01:32:43",
        bgcol: "#C4F1F9",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 8,
                name: "Al-Anfal",
                ayah: "75",
                time: "00:30:50"
            },
            {
                id: 9,
                name: "At-Tawbah",
                ayah: "129",
                time: "01:00:50"
            }
        ],
    },
    {
        id: "08",
        title: "DAY 8 🌓",
        icon: "",
        time: "01:32:25",
        bgcol: "#E9D8FD",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 10,
                name: "Yunus",
                ayah: "109",
                time: "00:45:00"
            },
            {
                id: 11,
                name: "Hud",
                ayah: "123",
                time: "00:46:22"
            }
        ],
    },
    {
        id: "09",
        title: "DAY 9 🌓",
        icon: "",
        time: "01:03:40",
        bgcol: "#FED7E2",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 12,
                name: "Yusuf",
                ayah: "111",
                time: "00:42:07"
            },
            {
                id: 13,
                name: "Ar-Ra'd",
                ayah: "43",
                time: "00:20:27"
            }
        ],
    },
    {
        id: "10",
        title: "DAY 10 🌔",
        icon: "",
        time: "00:37:40",
        bgcol: "#EDF2F7",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 14,
                name: "Ibrahim",
                ayah: "52",
                time: "00:20:29"
            },
            {
                id: 15,
                name: "Al-Hijr",
                ayah: "99",
                time: "00:16:05"
            }
        ],
    },
    {
        id: "11",
        title: "DAY 11 🌔",
        icon: "",
        time: "00:43:25",
        bgcol: "#C6F6D5",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 16,
                name: "An-Nahl",
                ayah: "128",
                time: "00:42:20"
            }
        ],
    },
    {
        id: "12",
        title: "DAY 12 🌔",
        icon: "",
        time: "01:07:50",
        bgcol: "#FEFCBF",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 17,
                name: "Al-Isra",
                ayah: "111",
                time: "00:33:19"
            },
            {
                id: 18,
                name: "Al-Kahf",
                ayah: "110",
                time: "00:33:26"
            }
        ],
    },
    {
        id: "13",
        title: "DAY 13 🌕",
        icon: "",
        time: "00:49:45",
        bgcol: "#B2F5EA",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 19,
                name: "Maryam",
                ayah: "98",
                time: "00:21:24"
            },
            {
                id: 20,
                name: "Ta-Ha",
                ayah: "135",
                time: "00:27:17"
            },
        ],
    },
    {
        id: "14",
        title: "DAY 14 🌕",
        icon: "",
        time: "00:57:00",
        bgcol: "#FEEBC8",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 21,
                name: "Al-Anbiya",
                ayah: "112",
                time: "00:26:20"
            },
            {
                id: 22,
                name: "Al-Hajj",
                ayah: "78",
                time: "00:29:35"
            },
        ],
    },
    {
        id: "15",
        title: "DAY 15 🌕",
        icon: "",
        time: "00:56:45",
        bgcol: "#BEE3F8",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 23,
                name: "Al-Mu'minun",
                ayah: "118",
                time: "00:24:56"
            },
            {
                id: 24,
                name: "An-Nur",
                ayah: "64",
                time: "00:30:43"
            },
        ],
    },
    {
        id: "16",
        title: "DAY 16 🌕",
        icon: "",
        time: "00:50:20",
        bgcol: "#FED7D7",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 25,
                name: "Al-Furqan",
                ayah: "77",
                time: "00:18:31"
            },
            {
                id: 26,
                name: "Ash-Shu'ara",
                ayah: "227",
                time: "00:30:43"
            },
        ],
    },
    {
        id: "17",
        title: "DAY 17 🌖",
        icon: "",
        time: "01:20:30",
        bgcol: "#C4F1F9",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 27,
                name: "An-Naml",
                ayah: "93",
                time: "00:26:14"
            },
            {
                id: 28,
                name: "Al-Qasas",
                ayah: "88",
                time: "00:31:53"
            },
            {
                id: 29,
                name: "Al-Ankabut",
                ayah: "69",
                time: "00:21:21"
            },
        ],
    },
    {
        id: "18",
        title: "DAY 18 🌖",
        icon: "",
        time: "01:10:50",
        bgcol: "#E9D8FD",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 30,
                name: "Ar-Rum",
                ayah: "60",
                time: "00:19:34"
            },
            {
                id: 31,
                name: "Luqmaan",
                ayah: "34",
                time: "00:12:04"
            },
            {
                id: 32,
                name: "As-Sajdah",
                ayah: "30",
                time: "00:08:53"
            },
            {
                id: 33,
                name: "Al-Ahzaab",
                ayah: "73",
                time: "00:29:13"
            },
        ],
    },
    {
        id: "19",
        title: "DAY 19 🌖",
        icon: "",
        time: "01:20:05",
        bgcol: "#FED7E2",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 34,
                name: "Saba",
                ayah: "54",
                time: "00:19:05"
            },
            {
                id: 35,
                name: "Faatir",
                ayah: "45",
                time: "00:17:47"
            },
            {
                id: 36,
                name: "Ya-Sin",
                ayah: "83",
                time: "00:17:49"
            },
            {
                id: 37,
                name: "As-Saaffaat",
                ayah: "182",
                time: "00:24:20"
            },
        ],
    },
    {
        id: "20",
        title: "DAY 20 🌗",
        icon: "",
        time: "01:12:35",
        bgcol: "#EDF2F7",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 38,
                name: "Saad",
                ayah: "88",
                time: "00:17:52"
            },
            {
                id: 39,
                name: "Az-Zumar",
                ayah: "75",
                time: "00:27:20"
            },
            {
                id: 40,
                name: "Ghafir",
                ayah: "85",
                time: "00:26:18"
            },
        ],
    },
    {
        id: "21",
        title: "DAY 21 🌗",
        icon: "",
        time: "01:00:45",
        bgcol: "#C6F6D5",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 41,
                name: "Fussilat",
                ayah: "54",
                time: "00:19:23"
            },
            {
                id: 42,
                name: "Ash-Shura",
                ayah: "53",
                time: "00:19:39"
            },
            {
                id: 43,
                name: "Az-Zukhruf",
                ayah: "89",
                time: "00:20:38"
            },
        ],
    },
    {
        id: "22",
        title: "DAY 22 🌗",
        icon: "",
        time: "01:01:40",
        bgcol: "#FEFCBF",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 44,
                name: "Ad-Dukhaan",
                ayah: "59",
                time: "00:09:47"
            },
            {
                id: 45,
                name: "Al-Jaathiyah",
                ayah: "37",
                time: "00:10:07"
            },
            {
                id: 46,
                name: "Al-Ahqaaf",
                ayah: "35",
                time: "00:16:07"
            },
            {
                id: 47,
                name: "Muhammad",
                ayah: "38",
                time: "00:12:27"
            },
            {
                id: 48,
                name: "Al-Fath",
                ayah: "29",
                time: "00:12:11"
            },
        ],
    },
    {
        id: "23",
        title: "DAY 23 🌗",
        icon: "",
        time: "00:52:40",
        bgcol: "#B2F5EA",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 49,
                name: "Al-Hujuraat",
                ayah: "18",
                time: "00:08:31"
            },
            {
                id: 50,
                name: "Qaaf",
                ayah: "45",
                time: "00:09:32"
            },
            {
                id: 51,
                name: "Adh-Dhaariyaat",
                ayah: "60",
                time: "00:09:13"
            },
            {
                id: 52,
                name: "At-Toor",
                ayah: "49",
                time: "00:08:15"
            },
            {
                id: 53,
                name: "An-Najm",
                ayah: "62",
                time: "00:07:51"
            },
            {
                id: 54,
                name: "Al-Qamar",
                ayah: "55",
                time: "00:08:13"
            },
        ],
    },
    {
        id: "24",
        title: "DAY 24 🌘",
        icon: "",
        time: "00:48:30",
        bgcol: "#FEEBC8",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 55,
                name: "Ar-Rahman",
                ayah: "78",
                time: "00:11:28"
            },
            {
                id: 56,
                name: "Al-Waqi'ah",
                ayah: "96",
                time: "00:12:06"
            },
            {
                id: 57,
                name: "Al-Hadeed",
                ayah: "29",
                time: "00:13:39"
            },
            {
                id: 58,
                name: "Al-Mujadila",
                ayah: "22",
                time: "00:10:15"
            },
        ],
    },
    {
        id: "25",
        title: "DAY 25 🌘",
        icon: "",
        time: "00:50:25",
        bgcol: "#BEE3F8",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 59,
                name: "Al-Hashr",
                ayah: "24",
                time: "00:10:36"
            },
            {
                id: 60,
                name: "Al-Mumtahanah",
                ayah: "13",
                time: "00:07:41"
            },
            {
                id: 61,
                name: "As-Saff",
                ayah: "14",
                time: "00:05:02"
            },
            {
                id: 62,
                name: "Al-Jumu'ah",
                ayah: "11",
                time: "00:03:43"
            },
            {
                id: 63,
                name: "Al-Munafiqoon",
                ayah: "11",
                time: "00:04:27"
            },
            {
                id: 64,
                name: "At-Taghabun",
                ayah: "18",
                time: "00:05:34"
            },
            {
                id: 65,
                name: "At-Talaq",
                ayah: "12",
                time: "00:06:10"
            },
            {
                id: 66,
                name: "At-Tahreem",
                ayah: "12",
                time: "00:06:08"
            },
        ],
    },
    {
        id: "26",
        title: "DAY 26 🌘",
        icon: "",
        time: "00:28:50",
        bgcol: "#FED7D7",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 67,
                name: "Al-Mulk",
                ayah: "30",
                time: "00:07:38"
            },
            {
                id: 68,
                name: "Al-Qalam",
                ayah: "52",
                time: "00:07:45"
            },
            {
                id: 69,
                name: "Al-Haaqqa",
                ayah: "52",
                time: "00:06:55"
            },
            {
                id: 70,
                name: "Al-Ma'aarij",
                ayah: "44",
                time: "00:05:27"
            },
        ],
    },
    {
        id: "27",
        title: "DAY 27 🌘",
        icon: "",
        time: "00:35:30",
        bgcol: "#C4F1F9",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 71,
                name: "Nuh",
                ayah: "28",
                time: "00:05:50"
            },
            {
                id: 72,
                name: "Al-Jinn",
                ayah: "28",
                time: "00:05:45"
            },
            {
                id: 73,
                name: "Al-Muzzammil",
                ayah: "20",
                time: "00:04:13"
            },
            {
                id: 74,
                name: "Al-Muddaththir",
                ayah: "56",
                time: "00:05:33"
            },
            {
                id: 75,
                name: "Al-Qiyamah",
                ayah: "40",
                time: "00:03:31"
            },
            {
                id: 76,
                name: "Al-Insaan|Ad-Dahr",
                ayah: "31",
                time: "00:05:41"
            },
            {
                id: 77,
                name: "Al-Mursalaat",
                ayah: "50",
                time: "00:04:55"
            },
        ],
    },
    {
        id: "28",
        title: "DAY 28 🌑",
        icon: "",
        time: "00:37:30",
        bgcol: "#E9D8FD",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 78,
                name: "An-Naba'",
                ayah: "40",
                time: "00:04:54"
            },
            {
                id: 79,
                name: "An-Naazi'aat",
                ayah: "46",
                time: "00:04:25"
            },
            {
                id: 80,
                name: "Abasa",
                ayah: "42",
                time: "00:03:49"
            },
            {
                id: 81,
                name: "At-Takweer",
                ayah: "29",
                time: "00:02:47"
            },
            {
                id: 82,
                name: "Al-Infitar",
                ayah: "19",
                time: "00:02:26"
            },
            {
                id: 83,
                name: "Al-Mutaffifeen",
                ayah: "36",
                time: "00:05:29"
            },
            {
                id: 84,
                name: "Al-Inshiqaaq",
                ayah: "25",
                time: "00:02:49"
            },
            {
                id: 85,
                name: "Al-Burooj",
                ayah: "22",
                time: "00:03:27"
            },
            {
                id: 86,
                name: "At-Taariq",
                ayah: "17",
                time: "00:01:49"
            },
            {
                id: 87,
                name: "Al-A'la",
                ayah: "19",
                time: "00:01:57"
            },
            {
                id: 88,
                name: "Al-Ghaashiyah",
                ayah: "26",
                time: "00:02:33"
            },
        ],
    },
    {
        id: "29",
        title: "DAY 29 🌑",
        icon: "",
        time: "00:15:35",
        bgcol: "#FED7E2",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 89,
                name: "Al-Fajr",
                ayah: "30",
                time: "00:03:42"
            },
            {
                id: 90,
                name: "Al-Balad",
                ayah: "20",
                time: "00:02:09"
            },
            {
                id: 91,
                name: "Ash-Shams",
                ayah: "15",
                time: "00:01:33"
            },
            {
                id: 92,
                name: "Al-Layl",
                ayah: "21",
                time: "00:02:01"
            },
            {
                id: 93,
                name: "Ad-Dhuha",
                ayah: "11",
                time: "00:01:14"
            },
            {
                id: 94,
                name: "Al-Inshirah",
                ayah: "8",
                time: "00:00:52"
            },
            {
                id: 95,
                name: "At-Tin",
                ayah: "8",
                time: "00:01:14"
            },
            {
                id: 96,
                name: "Al-Alaq",
                ayah: "19",
                time: "00:01:44"
            },
        ],
    },
    {
        id: "30",
        title: "DAY 30 🌑",
        icon: "",
        time: "00:19:10",
        bgcol: "#EDF2F7",
        sura: [
            {
                id: 1,
                name: "Al-Fatihah",
                ayah: "7",
                time: "00:01:03"
            },
            {
                id: 97,
                name: "Al-Qadr",
                ayah: "5",
                time: "00:00:54"
            },
            {
                id: 98,
                name: "Al-Bayyinah",
                ayah: "8",
                time: "00:02:15"
            },
            {
                id: 99,
                name: "Az-Zalzalah",
                ayah: "8",
                time: "00:01:09"
            },
            {
                id: 100,
                name: "Al-'Aadiyat",
                ayah: "11",
                time: "00:01:19"
            },
            {
                id: 101,
                name: "Al-Qaari'ah",
                ayah: "11",
                time: "00:01:12"
            },
            {
                id: 102,
                name: "At-Takaathur",
                ayah: "8",
                time: "00:01:12"
            },
            {
                id: 103,
                name: "Al-'Asr",
                ayah: "3",
                time: "00:00:36"
            },
            {
                id: 104,
                name: "Al-Humazah",
                ayah: "9",
                time: "00:01:07"
            },
            {
                id: 105,
                name: "Al-Feel",
                ayah: "5",
                time: "00:00:58"
            },
            {
                id: 106,
                name: "Quraish",
                ayah: "4",
                time: "00:00:52"
            },
            {
                id: 107,
                name: "Al-Maa'oon",
                ayah: "7",
                time: "00:01:07"
            },
            {
                id: 108,
                name: "Al-Kawthar",
                ayah: "3",
                time: "00:00:33"
            },
            {
                id: 109,
                name: "Al-Kaafiroon",
                ayah: "6",
                time: "00:01:03"
            },
            {
                id: 110,
                name: "An-Nasr",
                ayah: "3",
                time: "00:00:44"
            },
            {
                id: 111,
                name: "Al-Lahab",
                ayah: "5",
                time: "00:00:51"
            },
            {
                id: 112,
                name: "Al-Ikhlas",
                ayah: "4",
                time: "00:00:31"
            },
            {
                id: 113,
                name: "Al-Falaq",
                ayah: "5",
                time: "00:00:42"
            },
            {
                id: 114,
                name: "An-Naas",
                ayah: "6",
                time: "00:01:00"
            },
        ],
    },
]