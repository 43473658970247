import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
import { getMessaging } from "@firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyAXAhrgJSsPOThusqXZce4zFEjaGznHOv8",
    authDomain: "werecite-com.firebaseapp.com",
    projectId: "werecite-com",
    storageBucket: "werecite-com.appspot.com",
    messagingSenderId: "75658887925",
    appId: "1:75658887925:web:05f81c657c7d4b00e27bbc"
  };

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const messaging = getMessaging(app);

export default app;