import React from 'react'
import { ChakraProvider } from "@chakra-ui/react"
import Header from "./component/Header"
import Main from './component/Main'
import { Route, Routes } from 'react-router-dom'
import Sura from './component/Sura'

const App = () => {
  return (
    <ChakraProvider>
      <Header />
      <Routes>
        <Route path='/' element={<Main/>} />
        <Route path='/sura/:suraId' element={<Sura/>} />
      </Routes>
      
    </ChakraProvider>
  )
}

export default App