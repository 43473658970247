import React from 'react'
import { Heading, Card, CardHeader, CardBody, TableContainer, TableCaption, Table, Thead, Tr, Th, Tbody, Td, SimpleGrid, Box } from "@chakra-ui/react";
import {daylist as days} from '../data/days'
import { Link } from 'react-router-dom';

const Main = () => {
    
    return (
        <Box mt={'10px'} mx={'5px'}> 
            <SimpleGrid columns={{md: 2}} spacing='10px'> 
                {days.map((day) => ( 
                <Card key={day.id} background={day.bgcol}>
                    <CardHeader alignSelf='center'>
                        <Heading>{day.title}</Heading>
                    </CardHeader>
                    <CardBody>
                        <TableContainer>
                            <Table size='sm'>
                                <TableCaption>Estimated required time {day.time}</TableCaption>
                                <Thead>
                                    <Tr>
                                        <Th>Id</Th>
                                        <Th>Sura</Th>
                                        <Th>Ayah</Th>
                                        <Th>Open</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                {day.sura.map((surli) => (
                                    <Tr key={surli.id}>
                                        <Td>{surli.id}</Td>
                                        <Td>{surli.name}</Td>
                                        <Td>{surli.ayah}</Td>
                                        <Td><Link to={`/sura/${surli.id}`}>▶️</Link></Td>
                                    </Tr>
                                ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </CardBody>
                </Card> 
                ))} 
            </SimpleGrid>
        </Box>
    );
}

export default Main