import { Box, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

const Sura = () => {
  const { suraId } = useParams();
  const [sura, setSura] = useState();
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state
  const audioRef = useRef(null);

  useEffect(() => {
    const fetchSura = async () => {
      setIsLoading(true); // Set loading to true when fetching starts
      setError(null); // Reset error state

      try {
        const response = await fetch(
          `https://api.quran.com/api/v4/quran/verses/indopak?chapter_number=${suraId}`
        );

        if (!response.ok) {
          throw new Error('Failed to fetch sura data');
        }

        const data = await response.json();
        setSura(data); 
      } catch (error) {
        setError(error); // Set error state if fetch fails
      } finally {
        setIsLoading(false); // Set loading to false after fetch completes
      }
    };

    fetchSura();
  }, [suraId]); // Fetch again if suraId changes

  if (isLoading) {
    return <div>Loading...</div>; // Display loading message
  }

  if (error) {
    return <div>Error: {error.message}</div>; // Display error message
  }

  return (
    <Box px='15px'>
      <Box pt='10px' px="10px" align='center'>
        <audio ref={audioRef} controls>
          <source
            src={`https://download.quranicaudio.com/qdc/mishari_al_afasy/murattal/${suraId}.mp3`} 
            type="audio/mpeg"
          />
          Your browser does not support the audio element.
        </audio>
      </Box>
      
      {sura.verses.map((verse) => (
        <Box key={verse.id} borderBottom='1px' py='5px'>
          <Text 
            fontFamily={'indopak-2'}
            fontSize={'25px'}
            dir='rtl'
          >
            {verse.text_indopak} {' '} {verse.verse_key}
          </Text>
        </Box>
      ))}
    </Box>
  );
};

export default Sura;
